export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'PieChartIcon',
  },
  {
    title: 'Unit Kerja',
    route: 'unit-kerja',
    icon: 'PackageIcon',
  },
]
