export default [
  {
    header: 'Usulan',
  },
  {
    title: 'Perubahan Data',
    icon: 'CopyIcon',
    children: [
      {
        title: 'Pribadi',
        route: 'usulan-pribadi',
      },
      {
        title: 'Status Kepegawaian',
        route: 'usulan-status-kepegawaian',
      },
      {
        title: 'Pangkat',
        route: 'usulan-pangkat',
      },
      {
        title: 'Jabatan',
        route: 'usulan-jabatan',
      },
      {
        title: 'Keluarga',
        route: 'usulan-keluarga',
      },
      {
        title: 'Catatan Hukuman',
        route: 'usulan-catatan-hukuman',
      },
      {
        title: 'Pendidikan',
        route: 'usulan-pendidikan',
      },
      {
        title: 'Diklat',
        route: 'usulan-diklat',
      },
    ],
  },
]
