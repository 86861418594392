export default [
  {
    header: 'Administrasi',
  },
  {
    title: 'Pegawai',
    icon: 'UserIcon',
    children: [
      {
        title: 'Jabatan',
        route: 'pegawai-jabatan',
      },
      {
        title: 'Pangkat',
        route: 'pegawai-pangkat',
      },
      {
        title: 'Keluarga',
        route: 'pegawai-keluarga',
      },
      {
        title: 'Alamat',
        route: 'pegawai-alamat',
      },
      {
        title: 'Status Kepegawaian',
        route: 'pegawai-status-kepegawaian',
      },
      {
        title: 'Pendidikan',
        route: 'pegawai-pendidikan',
      },
      {
        title: 'Diklat',
        route: 'pegawai-diklat',
      },
      {
        title: 'Penghargaan',
        route: 'pegawai-penghargaan',
      },
    ],
  },
  {
    title: 'Kehadiran',
    icon: 'ClockIcon',
    route: 'pegawai-kehadiran',
  },
]
