export default [
  {
    header: 'Dokumentasi',
  },
  {
    title: 'Petunjuk Penggunaan',
    route: 'petunjuk-penggunaan',
    icon: 'HelpCircleIcon',
  },
  {
    title: 'Bantuan',
    route: 'bantuan',
    icon: 'InfoIcon',
  },
]
