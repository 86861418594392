export default [
  {
    header: 'Layanan',
  },
  {
    title: 'Pengajuan',
    icon: 'CreditCardIcon',
    children: [
      {
        title: 'Kartu Pegawai',
        route: 'layanan-karpeg',
      },
      {
        title: 'Kartu Suami-Istri',
        route: 'layanan-karsu-karis',
      },
      {
        title: 'Izin Luar Negeri',
        route: 'layanan-izin-luar-negeri',
      },
      {
        title: 'Legalisir Ijazah PKN-STAN',
        route: 'layanan-legalisir-ijazah',
      },
    ],
  },
]
