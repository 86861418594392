export default [
  {
    title: 'Laporan',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Riwayat Hidup',
        route: 'laporan-riwayat-hidup',
      },
      {
        title: 'KP4',
        route: 'laporan-kp4',
      },
      {
        title: 'Perubahan Data',
        route: 'laporan-perubahan-data',
      },
      {
        title: 'Daftar Unit Kerja',
        route: 'laporan-daftar-unit-kerja',
      },
      {
        title: 'Daftar Pegawai',
        route: 'laporan-daftar-pegawai',
      },
    ],
  },
]
